import React from "react";
import ScrollspyNav from "react-scrollspy-nav";
import ZoomableScreenshot from "./zoomablescreenshot";

class GroundedWalkthrough extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return <div className="container-full-width p-0 m-0">
            <div className="row">
                <div className="d-none d-md-block col-sm-0 col-md-3 col-xl-2">
            <ScrollspyNav
                scrollTargetIds={["sec0", "sec1", "sec2", "sec3"]}
                offset ={-100}
                activeNavClass="is-active"
                scrollDuration="10"
                headerBackground="true"
            >
                    <nav className="navbar navbar-dark bg-dark flex-column align-items-stretch p-3 position-fixed h-100">
                        <nav className="nav nav-pills flex-column">
                            <a className="nav-link" href="#sec0">Introduction</a>
                            <a className="nav-link" href="#sec1">The Mysterious Machine</a>
                            <a className="nav-link" href="#sec2">The Oak Lab</a>
                            <a className="nav-link" href="#sec3">The Hedge Chip</a>
                        </nav>
                    </nav>
            </ScrollspyNav>
                </div>
            <div className="col-md-9 col-xl-10">
                <div className="container-full-width p-5">
                <h1>Grounded Walkthrough</h1>
                <p className="lead text-center">Written by Corey</p>
                <section id="sec0">
                    <p>Obsidian's 2022 game Grounded is great fun solo or with friends, but it's easy to get lost or to not know where to go next. NewGuide+'s walkthrough is here to provide hints on where each major story quest starts and how to get to them.</p>
                    <p>By default, each image shows the player's perspective. Click on them to zoom out and see where it's located on the minimap.</p>
                    <p>If you like these concise, easy-to-read and mobile-friendly guides, be sure to check out our other guides at <a href="https://newguideplus.com">NewGuide+</a>!</p>
                </section>
                <div id="sec1">
                    <h2>The Mysterious Machine</h2>
                    <section>
                        <p>Coming Soon!</p>
                    </section>
                </div>
                <div id="sec2">
                    <h2>The Oak Lab</h2>
                    <section>
                        <p>The easiest way to look for <b>the Oak Lab</b> is to simply look up for the Oak tree. Once there, you will need to circle around it to find the entrance to the lab.</p>
                        <ZoomableScreenshot
                            imageURLs={["oaktreezoom0.png", "oaktreezoom1.png"]}
                        ></ZoomableScreenshot>
                    </section>
                </div>
                <div id="sec3">
                    <h2>The Hedge Chip</h2>
                    <section>
                        <p>BURG-L marks the <b>Hedge Chip</b> on the southeast corner of your map. However, it is not reachable from there. To start your journey to the Hedge Lab, you must first head for the <b>Hedge Ascent</b>, southwest of the Super Chip marker.</p>
                        <ZoomableScreenshot
                            imageURLs={["hedgelabzoom0.png", "hedgelabzoom1.png"]}
                        ></ZoomableScreenshot>
                        <p>Once you arrive at this lab, you will need to start heading upwards through the trees. It's a long journey with several miniature labs and ziplines along the way, until you eventually reach the <b>Hedge Lab</b>. You will likely die several times here, either by fall damage or from spiders, so it may be helpful to bring the supplies for a Lean-To to set your respawn point nearby.</p>
                        <ZoomableScreenshot
                            imageURLs={["hedgelab2zoom0.png", "hedgelab2zoom1.png"]}
                        ></ZoomableScreenshot>
                    </section>
                </div>
            </div>
        </div>
        </div>
        </div>
    }
}

export default GroundedWalkthrough;