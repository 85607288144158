import GroundedHome from './components/grounded-home';
import './App.css';

function App() {
  return (
    <div className="App">
      <nav className="p-2 navbar navbar-dark bg-dark fixed-top shadow" style={{justifyContent: "unset", height: "75px", fontSize: "25px"}}>
        <a href="https://www.newguideplus.com" className="navbar-brand" style={{zIndex:"2"}}>
          New<span style={{color: "#96be25"}}><strong>Guide</strong></span>+
        </a>
        <div className="guide-name" style={{zIndex:"1"}}>
          <a style={{textDecoration:"none"}}><span className="ml-2" style={{color: "#96be25"}}><b>Grounded</b></span></a>
        </div>
      </nav>
        <main style={{marginTop: "75px"}}>
          <GroundedHome />
        </main>
    </div>
  );
}

export default App;
