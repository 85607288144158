import React from "react";
import GroundedWalkthrough from "./grounded-walkthrough";
import GroundedMutations from "./grounded-mutations";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        element:
        <div className="container-full-width">
            <h1>Grounded Guides</h1>
            <div className="row mt-5">
                <a className="col-12 col-md-6" style={{textDecoration: "none", color: "#000000"}} href={'/walkthrough'}><div className="card">
                    <div className="card-body">
                        <h2 className="card-title">Walkthrough</h2>
                        <p className="card-text">Hints on where to go for each main story quest in the game</p>
                    </div>
                </div></a>
                <a className="col-12 col-md-6" style={{textDecoration: "none", color: "#000000"}} href={'/mutations'}><div className="card">
                    <div className="card-body">
                        <h2 className="card-title">Mutations</h2>
                        <p className="card-text">How to unlock each mutation in the game</p>
                    </div>
                </div></a>
            </div>
        </div>
    },
    {
        path: "mutations",
        element: <GroundedMutations />
    },
    {
        path: "walkthrough",
        element: <GroundedWalkthrough />
    }]);

class GroundedHome extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            curForm: ""
        }
    }

    render(){
        return (<RouterProvider router={router} />);
    }
}

export default GroundedHome;