import React from "react";
class ZoomableScreenshot extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            zoomLevel: 0,
            zooms: props.imageURLs
        };
    }

    handleClick(){
        if(this.state.zoomLevel == this.state.zooms.length - 1){
            this.setState({
                zoomLevel: 0
            });
        } else {
            this.setState({
                zoomLevel: this.state.zoomLevel + 1
            })
        }
    }

    hasNextImage(){
        return this.state.zooms.length > 1;
    }

    getNextImage(){
        if(this.state.zoomLevel == this.state.zooms.length - 1){
            return this.state.zooms[0];
        } else {
            return this.state.zooms[this.state.zoomLevel + 1];
        }
    }

    render(){
        return (<div className="screenshotContainer text-center" style={{cursor: "pointer"}} onClick={() => this.handleClick()}>
            <figure className="figure" style={{maxWidth: "1000px", maxHeight: "562px", position: "relative"}}>
                <img className="figure-img img-fluid rounded" style={{width: "100%", height: "100%", zIndex: "0"}} src={this.state.zooms[0]}/>
                {this.hasNextImage() && <img style={{zIndex: "1"}} className={this.state.zoomLevel == 1 ? "fullImage" : "miniImage"} src={this.state.zooms[1]} />}
                <figcaption className="figure-caption text-start">Click to enlarge the minimap</figcaption>
            </figure>
        </div>)
    }
}

export default ZoomableScreenshot;