import React from "react";
class GroundedMutations extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mutations: [],
            filteredMutations: [],
            unfilteredMutations: [],
            filters: []
        }
    }

    componentDidMount(){
        fetch("https://newguideplus.com/api/grounded/mutations")
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        mutations: result.map(v => {v.isHovered = false; return v;})
                    }, this.calculateFilters);
                }
            );
    }

    calculateFilters(){
        var allTags = [];
        for(var value of this.state.mutations){
            if(!value.tags){
                return;
            }
            for(var tag of value.tags){
                if(!allTags.includes(tag)){
                    allTags.push(tag);
                }
            }
        }
        allTags.sort();
        var allFilters = allTags.map((v) => {return {name: v, active: true}});
        this.setState({filters: allFilters}, this.calculateFilteredList);
    }

    calculateFilteredList(){
        var filteredMutations = [];
        var unfilteredMutations = [];
        for(var mutation of this.state.mutations){
            var shouldInclude = false;
            for (var tag of mutation.tags){
                if(this.getActiveFilters().includes(tag)){
                    shouldInclude = true;
                }
            }
            if(shouldInclude){
                filteredMutations.push(mutation);
            } else {
                unfilteredMutations.push(mutation);
            }
        }
        this.setState({
            filteredMutations: filteredMutations,
            unfilteredMutations: unfilteredMutations
        })
    }

    getActiveFilters(){
        return this.state.filters.filter(f => f.active).map(f => f.name);
    }

    handleClick(index){
        var newFilters = [...this.state.filters];
        newFilters[index].active = !this.state.filters[index].active;
        this.setState({filters: newFilters});
        this.calculateFilteredList();
    }

    handleMouseOver(index){
        var newMutations = [...this.state.mutations];
        for(var m of newMutations){
            if(m.tags.includes(this.state.filters[index].name)){
                m.isHovered = true;
            }
        }
        this.setState({mutations: newMutations});
    }

    handleMouseOut(index){
        var newMutations = [...this.state.mutations];
        for(var m of newMutations){
            m.isHovered = false;
        }

        this.setState({mutations: newMutations});
    }
    
    render (){
        return (<div className="container-full-width p-1">
            <div className="row">
                <h1>Grounded Mutation Unlock Guide</h1>
            </div>
            <div className="row mt-3 mb-3 justify-content-center">
                <h5 className="col-12 text-center">Filters</h5>
                {this.state.filters.map((value, index) => {return <div key={value.name} className="col-auto"><button className={value.active ? "btn btn-secondary" : "btn btn-outline-secondary"} onMouseOver={() => {this.handleMouseOver(index)}} onMouseOut={() => this.handleMouseOut()} onClick={() => {this.handleClick(index)}}>
                    {value.active && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16" style={{marginRight: "7px"}}>
                          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>}{value.name}</button></div>})}
            </div>
            <div className="row">
                {this.state.filteredMutations.map((value, index) => {return (<div key={value.name} className="col-6 col-md-4 col-xl-3"><div className={value.isHovered ? "card mb-2 highlight-card" : "card mb-2"}>
                    <div className="card-title text-center">
                        <h5>{value.name}</h5>
                    </div>
                    <div className="card-body">
                        <p>{value.effect}</p>
                        <hr/>
                        {value.phase3 && <span><p><b>Additional Phase 3 Effect:</b> {value.phase3}</p><hr/></span>}
                        <p><b>Unlock:</b> {value.from}</p>
                    </div>
                </div></div>)})}
            </div>
        </div>);
    }
}

export default GroundedMutations;